<template>
    <div class="first-all">
        <router-link :to="isZh ? '/zh' : '/en'" class="back"></router-link>
        <router-link :to="isZh ? '/zh' : '/en'" class="canyu"></router-link>
        <img src="@/assets/first/1.png" alt="" v-if="isZh">
        <img src="@/assets/first/2.png" alt="" v-if="isZh">
        <img src="@/assets/first/3.png" alt="" v-if="isZh">
        <img src="@/assets/first/4.png" alt="" v-if="isZh">
        <img src="@/assets/first/5.png" alt="" v-if="isZh">
        <img src="@/assets/first/e1.png" alt="" v-if="!isZh">
        <img src="@/assets/first/e2.png" alt="" v-if="!isZh">
        <img src="@/assets/first/e3.png" alt="" v-if="!isZh">
        <img src="@/assets/first/e4.png" alt="" v-if="!isZh">
        <img src="@/assets/first/e5.png" alt="" v-if="!isZh">
    </div>
</template>

<script>
export default {
    data() {
        return {
            isZh: true
        }
    },
    created() {
        if(this.$route.query.lang && this.$route.query.lang == 'en') {
            this.isZh = false
        } else {
            this.isZh = true
        }
    }
}
</script>

<style lang="less" scoped>
.first-all{width:100%;height:100%;font-size: 0;overflow-y: auto;
    img{width:100%}
    .back{position: absolute;top:20px;right:10px;width:50px;height:50px;z-index: 9;}
    .canyu{position: absolute;top:170px;left:120px;width:110px;height:40px;z-index: 9;}
}
</style>
